import {Input, InputWrapperDiv} from "../../../../../components/ui/Input";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";
import {Button} from "../../../../../components/ui/Button";
import {clientCreateAPI, clientDetailAPI, clientUpdateAPI, firmsListAPI} from "../../../../../services/api/clients";
import {useNavigate, useParams} from "react-router-dom";
import Select from "react-select"
import select_styles from "../../../../../layouts/elements";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRemove} from "@fortawesome/free-solid-svg-icons";
import useLocalStorageState from "use-local-storage-state";
import DarkCard from "../../../../../components/card/DarkCard";
import FormClient from "./FormClient";
import LightCard from "../../../../../components/card/LightCard";
import {validate} from "../../../../sign_up/components/validations";
import {phoneCountryList, popupData} from "../../../../../helpers/dictionary/users";
import PopupForm from "../../../../../components/ui/PopupForm";
import { Colors } from "../../../../../components/theme";
import {useSessionStorage} from "usehooks-ts";
import {options_in_session} from "../../../../../services/sessionData";
import {routesClientsBreadCrumbs} from "../../../../../helpers/breadCrumbs";


const field_list = {
    first_name: "",
    last_name: "",
    phoneCountry: "",
    telephone: "",
    email: "",
    responsible: [],
    note: "",
    firms: [],
}

const clientSave = async (data, id) => {
    let response
    try {
        if (id) {
            response = await clientUpdateAPI(data, id)
        } else {
            response = await clientCreateAPI(data)
        }
        if (response.status === 201 || response.status === 204) {
            return {popupData: popupData.saveOK, id: response.data.client_pk}
        }
    } catch (e) {
        console.log("Failed to download clients list", e);
        return {popupData: popupData.saveError}
    }
}

const optionsFormat = (elem) => {
    return { label: elem.name, value: elem.id }
}

const firmList = async (params) => {
    try {
        if (params) {
            const [firmsData, clientDetailData] = await Promise.all([firmsListAPI(), clientDetailAPI(params)])
            const firms = firmsData.data.map(
                el => { return optionsFormat(el) }
            )
            return {firmsData: firms, clientDetailData: clientDetailData.data}
        }
        const response = await firmsListAPI()
        const firms_list_options = await response.data.map(
            el => { return optionsFormat(el) }
        )
        return firms_list_options
    } catch (e) {
        console.log("Failed to download clients list", e);
    }
}

const validationRules = {
    first_name: ["required"],
    last_name: ["required"],
    phoneCountry: ["required"],
    telephone: ["required"],
    email: ["email"],
    responsible: [],
    note: [],
    firms: [],
}


const ClientManager = () => {
    const [firms, setFirms] = useState([])  // lista firm w options niewybranych
    const [form, setForm] = useState(field_list)
    const [settings, setSettings] = useSessionStorage("options-in-session", options_in_session);
    const localStorageData = localStorage.getItem("formData");
    const [storageData, setStorageData] = useLocalStorageState("formData",localStorageData);
    const [popup, setPopup] = useState({show: false});
    const params = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    useEffect(() => {
        if (storageData) {
            setFirms(storageData.firms)
            setForm(storageData.form)
            localStorage.removeItem("formData")
        } else {
            const importDataState = async () => {
                const dataResult = await firmList(params.key)

                if (params.key) {
                    const detailAPI = () => {
                        try {
                            const details = dataResult.clientDetailData
                            details.phoneCountry = phoneCountryList.find(el => el.value === details.phoneCountry)
                            const Allfirms = dataResult.firmsData
                            const firmsNotSelected = Allfirms.filter(
                                el => !details.firms.some(({firm_pk}) => firm_pk === el.value )
                            )
                            setForm(details)
                            setFirms(firmsNotSelected)
                        } catch (e) {
                            console.log("Failed to download client detail", e);
                        }
                    }
                    detailAPI()
                } else {
                    setFirms([...dataResult])
                }
            }
            importDataState()
        }
    }, [])

    const changeHandler = (val, fieldName, firm_pk) => {
        if (fieldName === 'responsible') {
            const formFirmsCp = structuredClone(form.firms)
            const firmSelectedObj = formFirmsCp.find(el => el.firm_pk === firm_pk)
            if (firmSelectedObj) {
                firmSelectedObj.info = val
                setForm({
                    ...form,
                    "firms": formFirmsCp,
                });
            }
        } else if (fieldName === 'firms') { // dodaj z firms do form.firms - usun z firms
            if (val.value === 0) {
                return
            }
            // dodaj do form.firms
            const clientFirmsCp = structuredClone(form.firms)
            clientFirmsCp.push({
                info: "",
                firm_name: val.label,
                firm_pk: val.value,
            })

            const firmsClone = structuredClone(firms)
            // usun z firms
            const withOutVAL = firmsClone.filter(el => el.value !== val.value)

            setForm({
                ...form,
                "firms": clientFirmsCp
            })
            setFirms(withOutVAL);
        } else {
            const newEntry = {
                ...form,
                [fieldName]: val,
            }
            setForm(newEntry);
        }
    }

    const deleteHandler = (val) => {
        const formFirmsCP = structuredClone(form.firms)
        const formFirmUpdate = formFirmsCP.filter(firm =>firm.firm_pk !== val)

        const firmsItem = formFirmsCP.find(firm => firm.firm_pk === val)
        const firmsCp = structuredClone(firms)
        firmsCp.push({
            label: firmsItem.firm_name,
            value: firmsItem.firm_pk,
        })
        setFirms(firmsCp)
        setForm({
            ...form,
            "firms": formFirmUpdate
        })
    }

    const handleSave = async (event) => {
        event.preventDefault()
        const error = Object.entries(form).some(([key, value]) => {
            const test = validate(validationRules[key], value);
            if (test) {
                return true
            }
        })
        if (error) {return}
        const phone = `${form.phoneCountry.value}-${form.telephone}`
        const response = await clientSave({
            ...form,
            // 'firms': new_firms,
            telephone: phone,
        }, params.key)

        if (response.popupData.variant === 'success') {
            // set client selected in to offer
            setSettings({
                ...settings,
                clientId: response.id,
            })
        }
        setPopup(response.popupData)
    }

    const handleCreateFirm = () => {
        const data = {
            firms: firms,
            form: form,
        }
        setStorageData(data)
        navigate("/clients/companies/create")
    }

    const cancelChandler = () => {
        if (settings && settings.pathReturn) {
            navigate(settings.pathReturn)
        } else if (!params.key) {
            navigate("/clients/contacts")
        } else {
            navigate("/clients/contacts/details/"+params.key)
        }
    }

    const handlePopup = () => {
        setPopup({show: false})
        if (popup.variant === "success") {
            if (settings && settings.pathReturn) {
                navigate(settings.pathReturn)
            } else navigate("/clients/contacts/details/"+params.key)
        }
    }

    const breadCrumbsManager = () => {
        const sessionData = JSON.parse(sessionStorage.getItem("client_manager"))
        const action = sessionData["action_name"]

        if (action) {
            if (action === "clients_contacts_add_contact") {
                return routesClientsBreadCrumbs(t, action)
            }
            if (action === "clients_contacts_edit_contact") {
                return routesClientsBreadCrumbs(t, action, +params.key)
            }
        }
    }

    return (<>
        {popup.show
            ? <PopupForm
                handlePopup={handlePopup}
                variant={popup.variant}
                title={t(popup.title)}
                message={t(popup.message)}
                leftBtn={popup.leftBtn}
                rightBtn={popup.rightBtn}
                okBtn={popup.okBtn}
                context={popup.context}
            />
            : <>
            <form name="client" onSubmit={handleSave}>

            <DarkCard classNames='mb-3 row'>
                {breadCrumbsManager()}
                {/* DO ZROBIENIA SESSION DATA

                {session_data
                    clients_contacts_add_contact
                    routesClientsBreadCrumbs(t, session_data.action)

                    clients_contacts_edit_contact:
                    routesClientsBreadCrumbs(t, session_data.action, contactID=params.key)
                 
                    project_edit_contact
                    routesClientsBreadCrumbs(t, session_data.action, contactID=params.key, projectID='projectID')
			    }
                    
                */}
            </DarkCard>
            <DarkCard classNames='mb-3 row'>
                {params.key ?
                    <h1 className="mt-0 p-0">
                        {t("clients.contact_edit_page_title")}
                    </h1>
                :
                    <h1 className="mt-0 p-0">
                        {t("clients.contact_add_page_title")}
                    </h1>
                }

                <div className="col-lg-6 col-xxl-4 mb-4 ps-0">
                    <LightCard>
                        <RedHeader title={t("clients.details_title")}/>
                        <FormClient
                            form={form}
                            changeHandler={changeHandler}
                        />
                    </LightCard>
                </div>
                <div className="col-lg-6 col-xxl-4 mb-4">
                    <LightCard>
                        <RedHeader title={t("clients.additionals")}/>
                        <div className="row pb-2">
                            <div>
                                {t("profile.note")}
                                <textarea
                                    name="note"
                                    value={form.note}
                                    onChange={val => changeHandler(val.target.value, 'note')}
                                    style={{width: '100%'}}
                                />
                            </div>
                        </div>
                        <InputWrapperDiv>
                            <div className="customSelectParent" style={{position: 'relative'}}>
                                {t("profile.select_company")}
                                <Select
                                    name='firmsSelect'
                                    options={firms}
                                    value=""
                                    onChange={val => changeHandler(val, 'firms')}
                                    styles={select_styles}
                                    unstyled
                                />
                            </div>
                            <div>
                                {form.firms &&
                                    form.firms.map(firm => {
                                        return (
                                            <div key={firm.firm_pk} className="mt-3" style={{backgroundColor:'rgba(255,255,255,0.8)', padding:'10px', borderRadius:'15px'}}>
                                                <div className="row pb-2">
                                                    <InputWrapperDiv>
                                                        {t("profile.responsibility")} in &nbsp;
                                                        <a
                                                        name="firmSelName"
                                                        onClick={() => deleteHandler(firm.firm_pk)} >
                                                            {firm.firm_name} &nbsp;
                                                            <FontAwesomeIcon icon={faRemove} />
                                                        </a> :
                                                        <Input
                                                            name="responsible"
                                                            value={firm.info}
                                                            onChange={val => changeHandler(
                                                                val, 'responsible', firm.firm_pk
                                                            )}
                                                        />
                                                    </InputWrapperDiv>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </InputWrapperDiv>
                        <div style={{position:'relative', overflow:'hidden'}}>
                            <Button onClick={handleCreateFirm} variant="round" style={{float:'right'}}>
                                {t("clients.create_new_comp")}
                            </Button>
                        </div>
                    </LightCard>
                </div>
                <div className="ps-0">
                    <Button
                        type="submit"
                        style={{backgroundColor:`${Colors.green}`}}
                        variant="round"
                        className={'me-3'}
                    >
                        {t("common.button.save")}
                    </Button>
                    <Button
                        variant="round"
                        onClick={cancelChandler}
                        styling="bordered"
                        className={'me-3'}
                    >
                        {t("common.button.cancel")}
                    </Button>
                </div>
            </DarkCard>
            </form>
            </>}
    </>)
}

export default ClientManager

