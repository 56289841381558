import {InputWrapperDiv} from "../../../../../../components/ui/Input";
import {fr} from "date-fns/locale";
import Select from "react-select";
import {languagesList} from "../../../../../../helpers/dictionary/users";
import select_styles from "../../../../../../layouts/elements";
import SlideButton from "../../../../../../components/ui/SlideButton";
import React from "react";
import DatePicker from "react-datepicker";
import {useTranslation} from "react-i18next";
import {date_range_restriction} from "../../Sales/helpers/func_helpers";

const filteredLanguagesList = languagesList.filter(language =>
    ["2", "3", "4"].includes(language.value)
);

function ParametersPdfForm({formData, dispatch}) {
    const { t } = useTranslation()

    return (<>
        <div className="row">
            <div className="col-lg-6">
                <InputWrapperDiv>
                    <p className="p-0 m-0" style={{textAlign:'left'}}>{t("offer.create_offer.document_date")}</p>
                    <DatePicker
                        name='event_date'
                        selected={formData.createDate}
                        onChange={val => dispatch({
                            type: "SET_DATE",
                            value: val,
                        })}
                        dateFormat='dd.MM.yyyy'
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        minDate={date_range_restriction().startDate}
                        maxDate={date_range_restriction().endDate}
                        dropdownMode='select'
                        locale={fr}
                        wrapperClassName='dataInputWrapper'
                        className='dataInput'
                        readOnly={formData.readOnly}
                    />
                </InputWrapperDiv>
            </div>

            <div className="col-lg-6">
                <InputWrapperDiv>
                    {t("offer.create_offer.select_language")}
                    <Select
                        name='firms-list'
                        options={filteredLanguagesList}
                        value={formData.language}
                        onChange={val => dispatch({
                            type: "SET_LANGUAGE",
                            value: val,
                        })}
                        styles={select_styles}
                        unstyled
                        required="required"
                    />
                </InputWrapperDiv>
            </div>

            <div className="col-12 mt-3">
                <InputWrapperDiv>
                    <p className="m-0">
                        <span>{t("offer.create_offer.show_estro_grant")}</span>
                        <span className="ps-2"><i>{formData.grantEstroSum} €</i></span>
                    </p>
                    <SlideButton
                        type='yesNo'
                        val={formData.showEstroGrant}
                        setVal={val => dispatch({
                            type: "SET_SHOW_ESTRO_GRANT",
                            value: val,
                        })}
                    />
                </InputWrapperDiv>
            </div>
        </div>

        <div className="row mt-3">
            <div className="col-12">
                <p className="m-0">{t("offer.create_offer.additional_comment_title")}</p>
                
                {/*  Trzeba to tutaj zmienic aby wyswietlala sie komentarz do projektu pdf  */}
                <textarea
                    name={"estimate_comment"}
                    value={formData.comment}
                    onChange={(e) => dispatch({
                        type: "SET_ESTIMATE_COMMENT",
                        value: e.target.value,
                    })}
                    rows={6}
                    style={{width: "100%", fontSize: "19px"}
                    }
                />
            </div>
        </div>
    </>)
}

export default ParametersPdfForm;
