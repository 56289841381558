import axios from "axios";
import TokenService from "../token.service";


let tokenTimer;

const setTokenTimestamp = () => {
	const TOKEN_TTL = Number(localStorage.getItem("token_ttl")) || 0
	// const expiry = localStorage.getItem("expiry");
	// if (Date.now() > expiry) { // nie wazny
	// 	TokenService.clearLocalStorage();
	// 	window.location.replace("/login");
	// 	return
	// }
	localStorage.setItem("expiry", Date.now() + TOKEN_TTL)

	if (typeof tokenTimer === "number") {
		clearTimeout(tokenTimer)
	}

	tokenTimer = setTimeout(() => {
		TokenService.clearLocalStorage();
		window.location.replace("/login");
	}, TOKEN_TTL)
};

const axiosClient = (contentType = "application/json") => {
	const axiosInstance = axios.create({
		baseURL: process.env.REACT_APP_API_HOST,
		headers: {
			"Content-Type": contentType,
		},
	});

	axiosInstance.interceptors.request.use(requestConfig => {
		if (requestConfig?.headers) {
			const authToken = TokenService.getLocalAccessToken();

			if (authToken) {
				setTokenTimestamp()

				requestConfig.headers["Authorization"] = `Token ${authToken}`;
				// requestConfig.headers["Content-Type"] = 'multipart/form-data';


				// config.headers["x-access-token"] = token; // for Node.js Express back-end
			}
		}
		return requestConfig;
	});

	axiosInstance.interceptors.response.use(
		(response) => response,
		error => {
			const { response } = error;

			if (error.code === "ERR_NETWORK") {
				console.log("OTHER ERROR >>>>>>>: ", error.code)
				// window.location.replace('/network-error')
			} else {
				if (response.status === 401 ||
					response.data.errors === "authenticationCredentialsWereNotProvided." ||
					response.data.errors === "Invalid token."
				) {
					TokenService.clearLocalStorage();
					window.location.replace("/login");
				} else if (response.status === 404) {
					console.log("error 404: ", response)
				}
				// else if (response.status === 500) {
				// 	// window.location.replace('/network-error')
				// }
				else {
					console.log("OTHER ERROR >>>>>>>: ", response)
					// window.location.replace('/network-error')
				};
			}
		}
	);

	axiosInstance.defaults.timeout = 30 * 1000;

	return axiosInstance;
};

export default axiosClient;
