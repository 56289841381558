import {Text, View} from "@react-pdf/renderer";
import {styles, stylesStaffTable} from "./Styles/Styles";
import {useTranslation} from "react-i18next";
import {instrNameCol1, instrNameCol2, instrNameCol3, instrNameCol4} from "../../dictionary/users";
import React, { useState, useEffect } from 'react';

const Staff = ({eventStaff}) => {
    const { t } = useTranslation();
    const [staffSum, setStaffSum] = useState(0);

    const contextStaff = (el, i) => {
        const staffValue = eventStaff[el].value; // Store the staff value
        const numericValue = Number(staffValue); // Convert it to a number
    
       // Display the staff in the view
       return (
            <View key={i} style={stylesStaffTable.staffLine}>
                <Text style={stylesStaffTable.staffName}>{el}</Text>
                <Text style={stylesStaffTable.staffNumber}>{staffValue || "-"}</Text> {/* Display staffValue or a dash */}
            </View>
        );
    };

    // useEffect to calculate the staff_sum after the component mounts
    useEffect(() => {
        let sum = 0;

        // Loop through all the columns and calculate the total sum
        [...instrNameCol1, ...instrNameCol2, ...instrNameCol3, ...instrNameCol4].forEach(el => {
            const staffValue = eventStaff[el]?.value; // Check if value exists
            const numericValue = Number(staffValue);

            // Add valid numbers to the sum
            if (!isNaN(numericValue) && numericValue > 0) {
                sum += numericValue;
            }
        });

        setStaffSum(sum); // Update the state with the calculated sum
    }, [eventStaff]); // This effect runs when eventStaff changes

    return (
    <View style={{paddingTop:15, marginHorizontal: 30, wrap: 'nowrap'}}>
        <Text style={[styles.redTitle,{padding:'5px',paddingLeft: 0}]}>{t("offer.pdf_document.staff.title")} ({staffSum})</Text>
        <View style={stylesStaffTable.section}>

            {/* First Column Special style! */}
            <View style={[stylesStaffTable.column, {paddingLeft: 0}]}>
                {instrNameCol1.map((el, i) => contextStaff(el, i))}
            </View>

            {/* 2nd Column */}
            <View style={stylesStaffTable.column}>
                {instrNameCol2.map((el, i) => contextStaff(el, i))}
            </View>
            {/* 3rd Column */}
            <View style={stylesStaffTable.column}>
                {instrNameCol3.map((el, i) => contextStaff(el, i))}
            </View>
            {/* Last Column Special style */}
            <View style={stylesStaffTable.lastColumn}>
                {instrNameCol4.map((el, i) => contextStaff(el, i))}
            </View>
        </View>
    </View>
)
};

export default Staff
