const configEndpoints = {
    config: 'api/parameter-app',
    countriesList: 'api/countries',
    location: 'api/location/list',
    calcLocationList: 'api/location/calc_list',
    locationDetail: (key) => `api/location/details/${key}`,
    locationCreate: `api/location`,
    locationUpdate: (id) => `api/location/${id}`,
    room: `api/location/room`,
    roomItem: (id) => `api/location/room/${id}`,
};

const userEndpoints = {
    register: 'api/accounts/register',
    registerEdit: (shortCode) => `/api/accounts/register-edit/${shortCode}`,
    signIn: 'api/accounts/login',
    signOut: 'api/accounts/logout',
    currentRole: 'api/accounts/current-role',
    changeRole: 'api/accounts/change-role',
    activate: (token) => `api/accounts/activate-user/${token}`,
    sendResetMail: 'api/accounts/password-reset',
    profile: 'api/accounts/profile',
    profileUpdate: (id) => `api/accounts/profile-update/${id}`,
    getProfileDraft: `api/accounts/get-profile-draft`,
    profileToDraft: 'api/accounts/profile-to-draft',
    draftProfileUpdate: 'api/accounts/draft-profile-update',
    draftToProfile: 'api/accounts/draft-to-profile',
    resetPassword: 'api/accounts/password-reset-save',
    resetPasswordTokenCheck: (token) => `api/accounts/password-reset-save/${token}`,
    // TODO Change when endpoint added - delete
    deleteAccount: 'api/account/delete',
};

const instruments = {
    instrumentsList: 'api/instruments/list',
    instrumentEdit: (id) => `api/instruments/edit/${id}/`,
    instrumentsStaffList: 'api/instruments/list-staff',
    draftInstrumentsUpdate: 'api/accounts/draft-instruments-update',

};

const languages = {
    languagesList: 'api/languages',
};

const project = {
    shortData: 'api/project/projects/short-data',
    details: (id) => '/api/project/projects/'+id,
    asblSettings: 'api/project/asbl-settings',
    abslSaveSettings: 'api/project/asbl-settings',
    asblManagement: 'api/association-members/members',
    asblManagementPositions: 'api/association-members/positions-names',
    associationMemberCreate: 'api/association-members',
    associationMemberUpdate: (id) => 'api/association-members/'+id,
    associationMemberDelete: (id) => 'api/association-members/'+id,
    associationMemberDetails: (id) => 'api/association-members/'+id,
    asblBusinessDataDetails: 'api/business-data',
    asblBusinessDataUpdate: (id) => 'api/business-data/'+id,
    positionsOnDocument: 'api/association-members/positions-on-document',
    printingOnDocument: 'api/association-members/printing-on-document',
    printingOnDocumentSave: 'api/association-members/printing-on-document-save',
    firmLogo: 'api/firm-logo',
    estimate: 'api/project/estimate',
    feed: 'api/project/feed',
    feedList: (projectID) => 'api/project/feed-list/'+projectID,
    quotDetails: (quote_link) => 'api/project/client-verify-quote/'+quote_link,
    quoteFormSave: (quote_link) => 'api/project/client-quote-save/'+quote_link,
    changeStatusQuote: (id) => 'api/project/change-status-quote/'+id,
    deleteQuote: (id) => 'api/project/delete-quote/'+id,
    saveParameters: (id) => 'api/project/save-parameters/'+id,
    // showEstroGrant: (quote_link) => 'api/project/show-estro-grant-save/'+quote_link,
    quotePDFDetails: (quote_link) => 'api/project/client-pdf-data/'+quote_link,
};

const calculator = {
    calculations: 'api/accounts/calculation',
    calculationDetail: (id) => 'api/accounts/calculation/'+id,
    calculationDelete: (id) => 'api/accounts/calculation/'+id,
    calculationEdit: (id) => 'api/accounts/calculation/'+id,
    idFreeEventDate: 'api/accounts/calculation/is-free-event-date',
    quoteCreate: (id) => 'api/project/quote-create/'+id,
    quoteDetails: (id) => 'api/project/quote-details/'+id,
}

const clients = {
    clientsList: '/api/clients',
    // clientsListFirmName: '/api/clients/client_list',
    clientsCreate: '/api/clients',
    clientDetail: (id) => '/api/clients/'+id,
    clientUpdate: (id) => '/api/clients/'+id,
    clientProjectCreate: 'api/project/project-name',
}

const firms = {
    firmsList: 'api/clients-firms',
    firmDetails: (id) => 'api/clients-firms/'+id,
    firmsCreate: 'api/clients-firms',
    firmsUpdate: (id) => 'api/clients-firms/'+id,
    typesFirmList: 'api/clients-firms/types-firms',
}

export {
    configEndpoints,
    userEndpoints,
    instruments,
    languages,
    project,
    calculator,
    clients,
    firms
};

