import LightCard from "../../../../../../components/card/LightCard";
import Staff from "./Staff";
import React, {useEffect, useState} from "react";
import {quoteDataAPI, quoteDeleteAPI} from "../../../../../../services/api/project";
import {Link, useNavigate, useParams} from "react-router-dom";
import Events from "./Events";
import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import {useTranslation} from "react-i18next";
import QuoteBudget from "./QuoteBudget";
// import QuoteAcceptanceForm from "./QuoteAcceptanceForm";
import DarkCard from "../../../../../../components/card/DarkCard";
import {TxtTitles} from "../../../../../../helpers/breadCrumbs";
import {format_datetime_convert, parametersSessionINIT} from "../../../../../../helpers/tools";
import SlideButton from "../../../../../../components/ui/SlideButton";
import {Button} from "../../../../../../components/ui/Button";
import { Colors } from "../../../../../../components/theme";
import Logo from "../../../../../../components/ui/Logo";
import LanguageButtons from "../../../../../../components/ui/LanguageButtons";
// import {el} from "date-fns/locale";

const LogoStyle = {
    maxWidth: '140px',
    width: '100%',
}

const textAcceptance = (textChildren, t) => (
    <LightCard>
        <RedHeader title={t('project.quote_acceptance_isAccepted_title')}/>
        {textChildren}
    </LightCard>
)

const QuoteClientForm = ({children}) => {
    const [data, setData] = useState(null);
    const params = useParams();
    const {t} = useTranslation();
    const [sessionData, setSessionData] = useState(parametersSessionINIT);
    const navigate = useNavigate()
    // const [showEstroGrant, setShowEstroGrant] = useState(false);

    useEffect(() => {
        const quote_data = async () => {
            const response = await quoteDataAPI(params.quote_link)
            console.info("response", response)
            if (!response || response.status !== 200) {
                navigate('/project/quote-link/not-active')
            }

            if (response.data) {
                setData(response.data)

                const get_sessionData = JSON.parse(sessionStorage.getItem("project_quote_formular"));
                if (get_sessionData) {
                    setSessionData(get_sessionData);
                } else {
                    setSessionData({
                        quoteID: response.data.id,
                        projectID: response.data.project,
                        projectName: response.data.projectName,
                        estimateID: response.data.estimate,
                        clientView: true,
                    })
                }
            }
        }
        quote_data()
        return () => sessionStorage.removeItem("project_quote_formular");
    }, [])


    const handleDelete = async () => {
        const response = await quoteDeleteAPI(data.id)
        if (response.status !== 200) {
            console.log("Nie powiodło się usunięcie quote...")
            return
        }
        navigate("/projects/in-preparation/"+sessionData.projectID)
    }

    console.log("ssssssss", data)

    const pageContent = (
        <DarkCard classNames='mb-3 row'>
            <TxtTitles>{t("project.project_name_title")}</TxtTitles>
            <h1 className='mb-0 p-0' style={{marginTop:'-5px'}}>
                {sessionData.projectName}
            </h1>
            {data
                ? <>
                <p className="mt-3 px-0" style={{fontSize:'14px'}}>
                    {t("project.project_id_title")} P{sessionData.projectID} <br/>
                    {t("project.quote_id_title")} Q{data.id}
                </p>

                <LightCard classNames='mb-3'>
                    {console.log("sumstaff",data.sum_staff)}
                    <Staff staff={data.staff} kzmProject={true} titleSummary={""}/>
                </LightCard>

                <LightCard classNames='mb-3'>
                    <Events
                        eventDetails={data.eventDetails}
                        //estimationID={" (Q"+data.id+")"}
                        estimationID={""}
                        events={data.current_events}
                    />
                </LightCard>

                <div className="col-6 ps-0">
                    <LightCard classNames='mb-3'>
                        <RedHeader title={t('offer.create_offer.project_program_title')}/>
                        <p className="my-0 ps-2">{data.program}</p>
                    </LightCard>
                </div>

                <div className="col-6 pe-0">
                    <LightCard classNames='mb-3'>
                        <RedHeader title={t('project.budget_title')}/>
                        <QuoteBudget projectData={data} />
                    </LightCard>
                </div>

                {!sessionData.clientView && data.showEstroGrant ?
                    <LightCard classNames='mb-3'>
                        <RedHeader title={t("offer.pdf_document.texts.additional_infos_title")}/>
                        <p className="mb-0 ps-2">
                            {t(
                                'offer.pdf_document.texts.project_costs',
                                {estroSum:`${data.budget.total.client_cost}`, grantEstro:`${data.budget.grantEstroSum}`}
                            )}
                        </p>
                    </LightCard>
                    : null
                }
                {!sessionData.clientView ?
                    <div className="row">
                        {data.status && (
                            <p className="mb-0">
                                {t("offer.pdf_document.status.title")}
                            </p>
                        )}
                        {data.status === 'new' && (
                            <p style={{color:`${Colors.skyBlue}`}}>
                                {t("offer.pdf_document.status.new_message")}
                            </p>
                        )}     
                        {data.status === 'waiting' && (
                            <p style={{color:`${Colors.darkOrange}`}}>
                                {t("offer.pdf_document.status.waiting_message")}
                            </p>
                        )}       
                        {data.status === 'accepted' && (
                            <p style={{color:`${Colors.darkGreen}`}}>
                                {t(
                                    "offer.pdf_document.status.accepted_message",
                                    {person:`${data.acceptance_cn}`}
                                )}
                                <br/>
                                {t(
                                    "offer.pdf_document.status.signature_date",
                                    {dateTime:format_datetime_convert(new Date(data.acceptance_datetime))}
                                )}
                            </p>
                        )}            
                        {data.status === 'rejected' && (
                            <p style={{color:`${Colors.darkRed}`}}>
                                {t(
                                    "offer.pdf_document.status.rejected_message",
                                    {person:`${data.acceptance_cn}`}
                                )}
                                <br/>
                                {t(
                                    "offer.pdf_document.status.signature_date",
                                    {dateTime:format_datetime_convert(new Date(data.acceptance_datetime))}
                                )}
                                <br/>
                                {t("offer.pdf_document.status.reason_title")}
                                <br/>
                                {data.acceptance_reason}
                            </p>
                        )}                    
                    </div>
                    : null
                }

                {!sessionData.clientView
                    ? 
                    <div style={{overflow:'hidden'}}>
                        <Button
                                onClick={handleDelete}
                                variant="round"
                                bWidth="normal"
                                type="submit"
                                style={{backgroundColor:`${Colors.red}`, float:'right'}}
                                className="mx-2">
                                {t("common.button.delete_quote")}
                        </Button>
                    </div>
                    : null
                }

                {children}
                </>
                : null
            }
        </DarkCard>
    )

    return (<>
        {!sessionData.clientView ?
            <DarkCard classNames='mb-3 row'>
                <p className="mt-0 mb-0 breadcrumbs">
                    <span className="txt">{t("menu.projects",{ ns: 'menu' })}</span> &#9656;
                    <Link to={'/projects/in-preparation'}>{t("menu.inprep_projects",{ ns: 'menu' })}</Link> &#9656;
                    <Link to={'/projects/in-preparation/'+sessionData.projectID}>{t("breadcrumbs.project_title")} P{sessionData.projectID}</Link> &#9656;
                    <span className="txt">{t("breadcrumbs.public_view")}</span>
                </p>
            </DarkCard>
        : null
        }

        {!sessionData.clientView ? 
            pageContent 
        :  <div className="py-4" style={{backgroundColor:'#f7f4f1', marginTop: '-44px', marginBottom:'-85px'}}>
                <div className="container">
                    <div className='row align-items-center mb-3'>
                        <div className='col px-0'>
                            <Logo styling={LogoStyle}/>
                        </div>
                        <div className='col px-0'>
                            <div style={{maxWidth:'120px', marginLeft:'5px', marginRight:'5px0', float:'right'}}>
                                <LanguageButtons />
                            </div>
                        </div>
                    </div>
                    {pageContent}
                </div>
            </div>
        }
        
    </>)
}

export default QuoteClientForm