import React, {useEffect, useReducer} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {clientProjectCreateAPI, getClientsListFirmNameAPI} from "../../../../../../services/api/clients";
import DarkCard from "../../../../../../components/card/DarkCard";
import ProjectName from "./ProjectName";
import ClientSelect from "./ClientSelect";
import {useTranslation} from "react-i18next";
import {useSessionStorage} from "usehooks-ts";
import get_calculation_api from "../../../../../../helpers/PDFSupport/offers/get_calculation_api";
import {options_in_session} from "../../../../../../services/sessionData";
import {isObjectEmpty} from "../../../../../../helpers/tools";
import FirmsClientSelect from "./FirmsClientSelect";
import OtherEstimateData from "./OtherEstimateData";
// import {getPrintingDocSelected} from "../../../../../../services/functionsAPI";
import {estimateAPI} from "../../../../../../services/api/project";
import {Button} from "../../../../../../components/ui/Button";
import {Colors} from "../../../../../../components/theme";
import _ from "lodash";
import {calculationType} from "../../../../../../helpers/dictionary/users";
import PopupForm from "../../../../../../components/ui/PopupForm";
import {GeneratorPDF} from "./GeneratorPDF";
import {popupError, popupSuccess} from "../../../../../../components/ui/Popup/feedBack";
import data from "bootstrap/js/src/dom/data";
import { Link } from "react-router-dom";

const INITIAL_OPTIONS = { value: "", label: "-----" };
const popupInitial = {
	show: false,
}

const getClientsListAPI = async () => {
	try {
		const response = await getClientsListFirmNameAPI()
		if (response.status === 200) {
			return await response.data
		}
	} catch (e) {
		console.log(e)
		return false
	}
}

const saveEstimate = async (doc, key) => {
	const dataRequest = {
		calculation: key,
		client: doc.client.value,
		firm: doc.firm.value > 0 ? doc.firm.value : "",
		program: doc.program,
		comment: doc.comment,
		date: doc.estimateDate,
		language: doc.language.value,
		showEstroGrant: doc.showEstroGrant,
		project: doc.project.id,
		type: calculationType.estimate,
		current: true,
	}
	console.log("save estimate dataRequest", dataRequest);

	const response = await estimateAPI(dataRequest)
	return response.status === 200 ? response.data : false
}

const INITIAL_DOC = {
	project: {}, // {name: "", id: ""}
	estimateFormDataVisible: false,
	clientsList: INITIAL_OPTIONS,
	client: INITIAL_OPTIONS,
	firm: INITIAL_OPTIONS,
	calculation: {},
	program: "",
	comment: "",
	language: INITIAL_OPTIONS,
	estimateDate: "",
	showEstroGrant: false,
	popup: popupInitial,
	isGeneratePDF: false,
	estimateID: undefined,
}

function docReducer(prevDoc, action) {
	switch (action.type) {
		case "CREATE_PROJECT":
			if (prevDoc.project && !isObjectEmpty(prevDoc.project)) {
				return prevDoc
			} else {
				const newEntry = {...prevDoc, project: action.response, estimateFormDataVisible: true}
				return newEntry
			};
			break;
		case "SELECT_CLIENT":
			return {...prevDoc, client: action.client, firm: INITIAL_OPTIONS};
			break;
		case "CALCULATION_DATA":
			return {...prevDoc, calculation: action.calculation}
			break
		case "SET_CLIENTS_LIST":
		case "SELECT_FIRM":
		case "SET_PROGRAM":
		case "SET_COMMENT":
		case "SET_LANGUAGE":
		case "SET_SHOW_ESTRO_GRANT":
		case "SET_ESTIMATE_DATE":
			const key = action.key
			return {...prevDoc, [key]: action[key]}
			break
		case "UPDATE_FROM_SESSION":
			return {...prevDoc, ...action.data}
			break
		case "SET_GENERATE_PDF":
			return {...prevDoc, isGeneratePDF: action.isGeneratePDF, estimateID: action.estimateID}
			break
		case "SET_ESTIMATE_ID":
			return {...prevDoc, popup: action.response, estimateID: action.estimateID}
			break
		case "SET_POPUP":
			return {...prevDoc, popup: action.popup}
			break
		default:
			return prevDoc;
	}
	throw Error('Unknown action: ' + action);
}


const Offer = () => {
	const params = useParams();
	const [doc, docDispatch] = useReducer(docReducer, INITIAL_DOC, undefined)
	const navigate = useNavigate();
	const offer_processName = sessionStorage.getItem("offer_processName");
	const [sessionData, setSessionData] = useSessionStorage("options-in-session", options_in_session);
	const { t } = useTranslation()
	const return_path = "/"+params['*']

	useEffect(() => {
		const clients_list = async () => {
			const response =  await getClientsListAPI()
			if (response) {
				const data = response.map(el => {
					const firms = el.firms.map(firm => ({
						value: firm.firm_pk,
						label: firm.firm_name,
						details: {
							type: firm.firm_type.name,
							address: firm.firm_address,
						},
					}))
					firms.unshift(INITIAL_OPTIONS)
					const client_data = {
						first_name: el.first_name,
						last_name: el.last_name,
						telephone: el.telephone,
						phoneCountry: el.phoneCountry,
						email: el.email,
						street: el.street,
						number: el.number,
						post_code: el.post_code,
						place: el.place,
						country: el.country,
					}
					return {
						value: el.id,
						label: `${el.first_name} ${el.last_name}`,
						firms,
						client_data
					}
				})

				docDispatch({type: "SET_CLIENTS_LIST", clientsList: data, key: "clientsList"})

				if (sessionData && sessionData.client.value > 0) {
					const clientSelected = data.find(obj => obj.value === Number(sessionData.client.value))
					const client=  {
						value: clientSelected.value,
						label: clientSelected.label,
						"client_data": clientSelected.client_data,
						"firms": clientSelected.firms,
					}

					const firm = client.firms.find(obj => obj.value === Number(sessionData.firm.value))
					const estimation_date = sessionData.estimateDate ? new Date(sessionData.estimateDate) : ""
					docDispatch({
						type: "UPDATE_FROM_SESSION",
						data: {
						...sessionData,
							estimateDate: estimation_date,
							client,
							firm,
						}
					})
				}
			} else navigate("/network-error")
		}
		clients_list()

		const getCalculationAPI = async () => {
			const response = await get_calculation_api(params.key)
			docDispatch({type: "CALCULATION_DATA", calculation: response})
		}
		if (sessionData) {
			docDispatch({
				type: "CREATE_PROJECT",
				response: {
					name: sessionData.projectName,
					id: sessionData.projectId,
				}
			})
			docDispatch({type: "SET_PROGRAM", program: sessionData.projProgram, key: "program"})
			docDispatch({type: "SET_COMMENT", comment: sessionData.note, key: "comment"})
		}
		getCalculationAPI()
		setSessionData(null)
	}, [])

	const onSaveProjectName = (name) => {
		const projectCreateAPI = async () => {
			const response = await clientProjectCreateAPI({name: name});
			if (response.status === 201) {
				docDispatch({type: "CREATE_PROJECT", response: response.data})
			} else navigate("/network-error")
		};
		projectCreateAPI()
	}

	const handleCancel = () => {
		navigate("/sales/calculator/"+params.key);
	};

	const handleNewEdit = (edit=false) => {
		const docCopy = _.cloneDeep(doc);
		delete docCopy.calculation
		delete docCopy.clientsList
		docCopy["pathReturn"] = return_path
		setSessionData(docCopy)
	}

	const handleSaveEstimate = async () => {
		const response = await saveEstimate(doc, params.key)
		if (response) {
			docDispatch({type: "SET_ESTIMATE_ID", response: popupSuccess, estimateID: response.pk})
		} else docDispatch({type: "SET_POPUP", popup: popupError})
	}

	const handleGeneratePDF = async () => {
		docDispatch({type: "SET_GENERATE_PDF", isGeneratePDF: true, estimateID: undefined})
	}

	const handlePopup = (status) => {
		if (status.type === "save") {
			docDispatch({type: "SET_POPUP", popup: popupInitial})
		}
		if (status.type === "cancel") {
			docDispatch({type: "SET_POPUP", popup: popupInitial})
		}
		if (status.type === "ok") {
			navigate("/projects/in-preparation")
		}
	}

	return ( doc.popup.show
		? <PopupForm
			handlePopup={handlePopup}
			variant={doc.popup.variant}
			title={t(doc.popup.title)}
			message={t(doc.popup.message)}
			okBtn={doc.popup.okBtn}
			leftBtn={doc.popup.leftBtn}
			rightBtn={doc.popup.rightBtn}
		/>
		:<>
		<DarkCard classNames='mb-3 row'>
			<p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.sales", {ns: 'menu'})}</span> &#9656;
                <Link to={'/sales/saved_calculations'}>{t("menu.saved_calculations", {ns: 'menu'})} </Link> &#9656;
                <Link to={'/sales/calculator/'+params.key}>{t("breadcrumbs.calculation_title")} C{params.key} </Link> &#9656;
                <span className="txt">{t("breadcrumbs.create_project_offer")} </span>
            </p>
		</DarkCard>
		<DarkCard classNames='mb-3 row'>
			<h1 className='mt-0'>
				{t("offer.create_offer.title")}
			</h1>
			<ProjectName
				isVisible={doc.estimateFormDataVisible}
				project={doc.project}
				onSaveProjectName={onSaveProjectName}
				handleCancel={handleCancel}
				className="row"
			/>
			<div className="row">
				{doc.estimateFormDataVisible
					?<ClientSelect
						clientsList={doc.clientsList}
						client={doc.client}
						dispatch={docDispatch}
						handleNewEdit={handleNewEdit}
					/>
					: null
				}
				{doc.estimateFormDataVisible && doc.client.value > 0
					? (<><FirmsClientSelect
							firmList={doc.client.firms}
							firm={doc.firm}
							dispatch={docDispatch}
							handleNewEdit={handleNewEdit}
						/>
						<OtherEstimateData
								// client={doc.client.value}
								dispatch={docDispatch}
								doc={doc}
							/>
						{doc.client.value > 0
							? <div className="row mt-3">
								<div className="col">
									<Button
										variant='round'
										style={{backgroundColor: Colors.green, marginRight:'20px'}}
										onClick={handleSaveEstimate}>
										{t("common.button.save_estimate")}
									</Button>
									{doc.estimateDate && doc.language.value > 0
										? 	<Button
												variant='round'
												style={{backgroundColor: Colors.orange}}
												onClick={handleGeneratePDF}>
												{t("common.button.generate_pdf")}
											</Button>
										: null
									}
								</div>
							  </div>
							: null
						}
					</>)
					: null
				}
			</div>
		</DarkCard>
		{doc.isGeneratePDF
			?	<div className="row">
					<GeneratorPDF
						dataToRender={doc}
						offer_processName={offer_processName}
						dispatch={docDispatch}
						popupError={popupError}
						popupInitial={popupInitial}
						saveEstimate={saveEstimate}
						calculationID={params.key}
					/>
				</div>
			:	null
		}
		</>);
};

export default Offer;
