import DarkCard from "../../../../../components/card/DarkCard";
import LightCard from "../../../../../components/card/LightCard";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";
import { useTranslation } from "react-i18next";
import { Input,InputWrapperDiv } from "../../../../../components/ui/Input";
import { Button } from "../../../../../components/ui/Button";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {roomCreateAPI, roomDetailAPI, roomUpdateAPI} from "../../../../../services/api/locations";
import PopupMessage from "../../../../../components/ui/PopupMessage";
import { Colors } from "../../../../../components/theme";

const ImageStyle = {
    width: '230px',
    height: '230px',
};

function RoomAddEdit () {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams()
    const roomInit = {
        name: '',
        how_many_musicians: '',
        acoustics_quality: '',
        temperature: '',
        image: ''
    }
    const [room, setRoom] = useState(roomInit)
    const popupInit = {show: false, title: '', variant: 'error', message: ''}
    const [popup, setPopup] = useState(popupInit)
    const returnPath = `/locations/location/${params.key}`

    useEffect(() => {
        const getRoomItem = async () => {
            try {
                const response = await roomDetailAPI(params.idroom)
                setRoom(response.data)
            } catch (e) {
                console.log("Failed to download location details data")
            }
        }
        if (params.key) {
            getRoomItem()
        }

    }, [params.key])

    const handlePopup = (obj) => {
        if (popup.variant === 'success') {
            navigate(returnPath)
        }
        setPopup(popupInit)
    }

    const onChangeHandler = (e, attrName) => {
        const newEntry = {
            ...room,
            [attrName]: e
        }
        setRoom(newEntry)
    }

    const saveChandler = async () => {
        const roomSaveAPI = async (data) => {
            if (!data) {
                return
            }
            try {
                let response = undefined
                if (params.idroom) {
                    response = await roomUpdateAPI(data, params.idroom)
                } else if (params.key) {
                    response = await roomCreateAPI(data)
                } else return
                setPopup(true)
                if ((response.status === 201) || (response.status === 200)) {
                    setPopup({
                        show: true,
                        title: t("popups.save_data.title"),
                        variant: 'success',
                        message: t("popups.save_data.room_details") // The room has been added to the location.
                    })
                } else {
                    setPopup({
                        show: true,
                        title: t("popups.error_save_data.title"),
                        variant: 'error',
                        message: t("popups.error_save_data.room_details")
                    })
                }
            } catch (e) {
                setPopup({
                    show: true,
                    title: t("popups.error_save_data.title"),
                    variant: 'error',
                    message: t("popups.error_save_data.room_details")
                })
            }
        }

        if (room.name.length > 0) {
            const formData = new FormData()
            formData.append('name', room.name)
            formData.append('how_many_musicians', room.how_many_musicians)
            formData.append('acoustics_quality', room.acoustics_quality)
            formData.append('temperature', room.temperature)

            if (typeof room.image === 'object') {
                formData.append('image', room.image, room.image.name)
            }
            formData.append('location', params.key)
            await roomSaveAPI(formData)
        }
    }

    const cancelChandler = () => {
        navigate(returnPath)
    }

    return (<>
        {popup.show
            ? <PopupMessage
                setPopup={handlePopup}
                variant={popup.variant}
                title={popup.title}
                message={popup.message}
                okBtn={t("common.button.ok")}
            />
            : <>
            <DarkCard classNames='mb-3 row'>
                DO WYCIAGNIECIA Z SESSION DATA

                {/* DO ZROBIENIA SESSION DATA

                {session_data

                    add_room:
                    routesLocationsBreadCrumbs(t, session_data.action, locationID=params.key)

                    edit_room:
                    routesLocationsBreadCrumbs(t, session_data.action, locationID=params.key, roomID=room.id)
			    }
                    
                */}
            </DarkCard>

            <DarkCard classNames='mb-3 row'>
                {room.id ?
                    <h1 className="mt-0 p-0">
                        {t("location.edit_room_page_title")}
                    </h1>
                :
                    <h1 className="mt-0 p-0">
                        {t("location.add_room_page_title")}
                    </h1>
                }

                <div className="col-lg-6 col-xl-4 mb-4 ps-0">
                    <LightCard>
                        <RedHeader title={t("location.rooms.add_title")}/>

                        <InputWrapperDiv className="px-2">
                            {t("location.rooms.details.room_name")}
                            <Input
                                value={room.name}
                                onChange={e => onChangeHandler(e, 'name', )}
                                name='name'
                            />
                        </InputWrapperDiv>

                        <InputWrapperDiv className="px-2 mt-2">
                            {t("location.rooms.details.how_many_musicians")}
                            <Input
                                value={room.how_many_musicians}
                                onChange={e => onChangeHandler(e, 'how_many_musicians')}
                                name='how_many_musicians'
                            />
                        </InputWrapperDiv>

                        <InputWrapperDiv className="px-2 mt-2">
                            {t("location.rooms.details.sound_quality")}
                            <Input
                                value={room.acoustics_quality}
                                onChange={e => onChangeHandler(e, 'acoustics_quality')}
                                name='acoustics_quality'
                            />
                        </InputWrapperDiv>

                        <InputWrapperDiv className="px-2 mt-2">
                            {t("location.rooms.details.temp")}
                            <Input
                                value={room.temperature}
                                onChange={e => onChangeHandler(e, 'temperature')}
                                name='temperature'
                            />
                        </InputWrapperDiv>

                        <InputWrapperDiv className="px-2 mt-2">
                            {t("location.rooms.details.room_img")}
                            <br/>
                            {(room.image) &&
                                <div className="col-md-6">
                                    <img
                                        src={room.image}
                                        alt=''
                                        style={ImageStyle}
                                    />
                                </div>
                            }
                            <div>
                                {
                                    room.image &&
                                    typeof room.image === 'object' &&
                                    `${room.image.name} - ${room.image.type}`
                                }
                            </div>
                            <input
                                type="file"
                                name={'image'}
                                onChange={e => onChangeHandler(e.target.files[0], 'image')}
                            />
                        </InputWrapperDiv>

                    </LightCard>
                </div>
                <div className="ps-0">
                    <Button
                        style={{backgroundColor:`${Colors.green}`}}
                        variant="round"
                        onClick={saveChandler}
                        className={'me-3'}
                    >
                        {t("common.button.save")}
                    </Button>
                    
                    <Button
                        variant="round"
                        onClick={cancelChandler}
                        styling="bordered"
                        className={'me-3'}
                    >
                        {t("common.button.cancel")}
                    </Button>
                </div>
            </DarkCard>
            </>
        }
        </>
    )
}
export default RoomAddEdit;