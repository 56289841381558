import {Text, View} from "@react-pdf/renderer";
import dateFormat from "dateformat";
import React, {useEffect, useState} from "react";
import {stylesEventsTable, styles} from "./Styles/Styles";
import {useTranslation} from "react-i18next";
import {calcLocationListAPI} from "../../../services/api/locations";
import {isObjectEmpty} from "../../tools";


const Events = ({eventsList, eventsCosts, events}) => {
    const { t } = useTranslation();
    const [locationsList, setLocationsList] = useState([])

    useEffect(() => {
        const getLocationsList = async () => {
            try {
                const resp_list = await calcLocationListAPI()
                const response = resp_list.data.qs
                setLocationsList(response)
            } catch (ex) {
                console.log("Failed to download locations list.", ex);
            }
        }
        getLocationsList().then(r => {})
    }, [])

    const locationsListFind = (loc) => {
        const location = locationsList.find(el => el.pk === loc)
        return location ? location.name : t("offer.pdf_document.texts.to_define")
    }

    const compositionsListFind = (comp) => {
        if (comp) {
            return  comp.map(el => el.label).toString()
        } else return t("not_define")
    }

    const tableHeader = (
        <View style={[stylesEventsTable.tableRow, stylesEventsTable.tableHeader]}>
            <View style={[stylesEventsTable.tableCell, { width: '30%', paddingLeft: 0}]}>
                <Text>{t("offer.pdf_document.events.location")}</Text>
            </View>
            <View style={[stylesEventsTable.tableCell, { width: '23%' }]}>
                <Text>{t("offer.pdf_document.events.date_time")}</Text>
            </View>
            <View style={[stylesEventsTable.tableCell, { width: '22%' }]}>
                <Text>{t("offer.pdf_document.events.staff")}</Text>
            </View>
            <View style={[stylesEventsTable.tableCell, { width: '15%' }]}>
                <Text>{t("offer.pdf_document.events.type")}</Text>
            </View>
            <View style={[stylesEventsTable.tableCell, { width: '10%', textAlign: 'right' }]}>
                <Text>{t("offer.pdf_document.events.costs")}</Text>
            </View>
        </View>
    )

    if ((!Array.isArray(eventsList)) || (eventsList.length === 0)) {
        return (
            /* Widok gdy nie mamy more details */
            <View style={stylesEventsTable.table}>
                <Text style={[styles.redTitle,{marginBottom:'9px'}]}>{t("offer.pdf_document.texts.events")}</Text>

                <View style={[stylesEventsTable.tableRow, { width: '100%'}]}>
                    <Text>{t("offer.pdf_document.events.rehearsals")} {events.rehearsals}</Text>
                </View>
                <View style={[stylesEventsTable.tableRow, { width: '100%'}]}>
                    <Text>{t("offer.pdf_document.events.raccords")} {events.raccords}</Text>
                </View>
                <View style={[stylesEventsTable.tableRow, { width: '100%'}]}>
                    <Text>{t("offer.pdf_document.events.concerts")} {events.concerts}</Text>
                </View>
                <View style={[stylesEventsTable.tableRow, { width: '100%', paddingTop:'1%'}]}>
                    <Text>{t("offer.pdf_document.events.define_exact_dates_txt")}</Text>
                </View>
            </View>
        )
    } else if (!isObjectEmpty(eventsCosts)) {
        const events = eventsList.slice().sort((a, b) => {
            return new Date(a.eventDate).getTime() - new Date(b.eventDate.getTime())
        })

        return (
            /* Widok gdy more details */
            <View style={stylesEventsTable.table}>
                {tableHeader}
                {/* Table data */}
                {events.map((row, index) => (
                    <View key={index} style={stylesEventsTable.tableRow}>
                        <View style={[stylesEventsTable.tableCell, { width: '30%', paddingLeft: 0}]}>
                            <Text>{locationsListFind(row.location)}</Text>
                        </View>
                        <View style={[stylesEventsTable.tableCell, { width: '23%' }]}>
                            <Text>{dateFormat(row.eventDate, "dd.mm.yyyy")} - {row.startTime.slice(0, 5)}</Text>
                        </View>
                        <View style={[stylesEventsTable.tableCell, { width: '22%' }]}>
                            <Text>{compositionsListFind(row.composition)}</Text>
                        </View>
                        <View style={[stylesEventsTable.tableCell, { width: '15%' }]}>
                            <Text>{t("offer.pdf_document.event_type."+row.type)}</Text>
                        </View>
                        <View style={[stylesEventsTable.tableCell, { width: '10%', textAlign: 'right' }]}>
                            <Text>{eventsCosts[row.key].toFixed(2)} €</Text>
                        </View>
                    </View>
                ))}
            </View>
        );
    } else return null
}

export default Events
