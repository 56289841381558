import React, {useState} from "react";
import LightCard from "../../../../../../components/card/LightCard";
import {useTranslation} from "react-i18next";
import {Input} from "../../../../../../components/ui/Input";
import {Button} from "../../../../../../components/ui/Button";
import {QuoteAcceptanceFormSave} from "../../../../../../services/api/project";
import {useNavigate, useParams} from "react-router-dom";
import { Colors } from "../../../../../../components/theme";


const QuoteAcceptanceForm = () => {
    const {t} = useTranslation()

    const OPTIONS = [
        {label: t("offer.acceptance.yes"), value: 1},
        {label: t("offer.acceptance.no"), value: 0},
    ]

    const INITFORM = {
        acceptance_cn: "",
        acceptance_firm: "",
        acceptance_isAccepted: OPTIONS.find(el => el.value === 1),
        acceptance_reason: "",
        acceptance_signature: "",
    }
    const navigate = useNavigate()
    const [data, setData] = useState(INITFORM)
    const params = useParams()

    const changeHandler = (value, fieldName) => {
        if (fieldName === "acceptance_isAccepted") {
            setData({...data, [fieldName]: OPTIONS.find(el => el.value === +value)});
        } else setData({...data, [fieldName]: value})
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        const dataCopy = structuredClone(data)
        dataCopy.acceptance_isAccepted = data.acceptance_isAccepted.value == 1 ? "accepted" : "rejected"
        dataCopy.acceptance_datetime = new Date()
        const result = await QuoteAcceptanceFormSave(params.quote_link, dataCopy)
        if (result.status === 200) {
            navigate('/client-quote-confirmation/'+params.quote_link)
        }
        console.info(result)
    }

    return (<>
        <form name="quote_save" onSubmit={submitHandler}>
            <LightCard>
                <span>{t('offer.acceptance.txt_1')}</span>
                <Input
                    name="client_cn"
                    value={data.acceptance_cn}
                    placeholder={t('offer.acceptance.client_name')}
                    type={"text"}
                    style={{minWidth: "250px", width: "50%", marginLeft: "10px"}}
                    onChange={(val) => changeHandler(val, "acceptance_cn")}
                    required
                />
                <br/>
                <span>{t('offer.acceptance.txt_2')}</span>
                <br/>
                <Input
                    name="client_company"
                    value={data.acceptance_firm}
                    placeholder={t('offer.acceptance.comp_name')}
                    type={"text"}
                    style={{minWidth: "250px", width: "50%", marginLeft: "0px", marginBottom:'20px'}}
                    onChange={(val) => changeHandler(val, "acceptance_firm")}
                    required
                />
                
                <Input
                    name="client_acceptance"
                    type={"radio"}
                    options={OPTIONS}
                    value={data.acceptance_isAccepted.value}
                    style={{minWidth: "250px", width: "50%", marginLeft: "10px"}}
                    onChange={val => changeHandler(val, "acceptance_isAccepted")}
                    required
                />
                {data.acceptance_isAccepted.value === 0 && (
                    <div className='mt-2'>
                        <textarea
                            name="reason"
                            value={data.acceptance_reason}
                            placeholder = {t('offer.acceptance.no_reason')}
                            onChange={val => changeHandler(
                                val.target.value,
                                "acceptance_reason"
                            )}
                            style={{width: '100%', height: '100px'}}
                            required
                        />
                    </div>
                )}
                <br/>
                <span>{t('offer.acceptance.txt_3')}</span>
                <br/>
                <br/>
                <span>{t('offer.acceptance.digital_sign_title')}</span>
                <br/>
                <Input
                    name="signature"
                    options={OPTIONS}
                    value={data.acceptance_signature}
                    placeholder={t('offer.acceptance.client_name')}
                    type={"text"}
                    style={{minWidth: "250px", width: "50%", marginLeft: "0px"}}
                    onChange={val => changeHandler(val, "acceptance_signature")}
                    required
                />
                <br/>
                <span style={{color:`${Colors.Gray}`,fontSize:'11px'}}>{t('offer.acceptance.txt_4')}</span>

            </LightCard>

            <Button
                type={"submit"}
                variant="round"
                bWidth="normal"
                className="mx-2"
                style={{backgroundColor:`${Colors.green}`}}
                >
                {t("common.button.submit")}
            </Button>
        </form>
    </>)
}


export default QuoteAcceptanceForm
