import {Link, useNavigate} from "react-router-dom";
import LordIcon from "../../../../../components/ui/LordIcon";
import {Colors} from "../../../../../components/theme";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {firmsListAPI} from "../../../../../services/api/clients";
import DarkCard from "../../../../../components/card/DarkCard";


const FirmsElement = styled.div `
    border-radius: 5px;
    padding: 5px 15px;
    margin: 15px 0px;
    background-color: ${Colors.lightBG};
`
const FirmsDetails = styled.div `
    div {
        margin: 3px 15px;

        &:first-child {
            margin-left: 0px
        }
        
        &:last-child {
            margin-right: 0px
        }
    }
`
const FirmsName = styled.div `
    @media (min-width: 992px) {
        width: 70%;
    }
`
const FirmsType = styled.div `
    @media (min-width: 992px) {
        width: 70%;
    }
`
const FirmsPlace = styled.div `
    @media (min-width: 992px) {
        width: 33%;
    }
`
const FirmsCountry = styled.div `
    @media (min-width: 992px) {
        width: 40%;
    }
`

const get_firms_list = async () => {
    try {
        const firmsData = await firmsListAPI()
        return firmsData.data
    } catch (e) {
        console.log("Failed to download clients list", e);
        return
    }
}


const ClientsList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [firms, setFirms] = useState([])

    useEffect(() => {
        sessionStorage.removeItem('options-in-session')
        const getData = async () => {
            const response = await get_firms_list()
            if (response) {
                setFirms(response)
            } else {
                console.log("Failed to download firms list.")
            }
        }
        getData()
    }, [])

    // const handleClick = (firm_id) => {
    //     navigate("details/"+firm_id)
    // }

    const actionHandler = (action, firm_id) => {
        if (action === "create") {
            sessionStorage.setItem("client_manager", JSON.stringify({"action_name": "clients_contacts_add_company"}))
            navigate("create/" + firm_id)
            return
        }
        sessionStorage.setItem("client_manager", JSON.stringify({"action_name": "clients_contacts_edit_contact_add_company"}))
        navigate("details/"+firm_id)
    }

    return(<>
        <DarkCard classNames='mb-3 row'>
            <p className="m-0 p-0 breadcrumbs">
                <span className="txt">{t("menu.clients",{ ns: 'menu' })}</span> &#9656; 
                <Link to={'/clients/companies'}>{t("menu.companies",{ ns: 'menu' })}</Link>
            </p>
        </DarkCard>

        <DarkCard classNames='mb-3 row'>
            <h1 className="mt-0 p-0">
                {t("clients.comp_main_page_title")}
            </h1>

            <div className="row ps-0">
                <div className="col-sm-8">
                    <Link className="addGroup" to={'create'} onClick={() => actionHandler("create")} >
                        <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup'/>
                        <span className="ps-2">{t("common.button.add_company")}</span>
                    </Link>
                </div>
                <div className="col-sm-4 mt-3 mt-sm-0">
                    <input style={{width: '100%'}} placeholder="Search" disabled></input>
                </div>
            </div>
            <div className="mt-4 ps-0">
                {firms.length >0 ?
                    firms.map(firm =>
                    <FirmsElement className={"d-flex flex-column"} key={firm.id}>
                        <FirmsDetails className="d-flex flex-row flex-wrap flex-md-nowrap" />
                        <div className="d-flex flex-row flex-wrap flex-md-nowrap py-1" style={{width: '95%'}}>
                            <FirmsName>
                                <a onClick={() => actionHandler("edit", firm.id)}>
                                    <b>{firm.name}</b>
                                </a>
                            </FirmsName>
                            <FirmsType>
                                <span>{firm.type}</span>
                            </FirmsType>
                            <FirmsPlace>
                                <span>{firm.place}</span>
                            </FirmsPlace>
                            <FirmsCountry>
                                <span>{firm.country}</span>
                            </FirmsCountry>
                        </div>
                    </FirmsElement>
                )
                    : <p>{t("No company yet!")}</p>
                }

            </div>
        </DarkCard>
    </>)
}

export default ClientsList