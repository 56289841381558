import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components"
import { Colors } from "../../../../../../components/theme";

const types_sum = ['musicians','renting','transport','administration','archiving']

const TableHeader = styled.span`
    font-weight: bold;
    color: ${Colors.darkRed};
`

const Summary = ({typeSum, data}) => {
    return (
        <tr>
            <td className="px-2">{typeSum}</td>
            <td className="px-2 txtRight">{parseFloat(data.client_cost).toFixed(2)} €</td>
            <td className="px-2 txtRight">{parseFloat(data.estro_cost).toFixed(2)} €</td>
        </tr>
    )
}


export default function Budget({projectData}) {
    const {t} = useTranslation()

    return (<>
        <div>
            <table>
                <thead>
                    <tr>
                        <td className="px-2 pb-2"><TableHeader>{t('project.service_title')}</TableHeader></td>
                        <td className="px-2 pb-2"><TableHeader>{t('project.client_costs_title')}</TableHeader></td>
                        <td className="px-2 pb-2"><TableHeader>{t('project.estro_costs_title')}</TableHeader></td>
                    </tr>
                </thead>
                <tbody>
                {types_sum.map((type, index) => (<Summary
                    key={index}
                    typeSum={t(`offer.pdf_document.texts.${type}`)}
                    data={projectData.budget[type]}
                />))}
                </tbody>
                <tfoot>
                <tr>
                    <td className="px-2 pt-2"><b>{t('project.budget_total')}</b></td>
                    <td className="px-2 pt-2 txtRight"><b>{parseFloat(projectData.budget.total.client_cost).toFixed(2)} €</b></td>
                    <td className="px-2 pt-2 txtRight"><b>{parseFloat(projectData.budget.total.estro_cost).toFixed(2)} €</b></td>
                </tr>
                </tfoot>
            </table>
        </div>
        <div className="mt-3 ps-2">{t("rates.estro_grant")}: {parseFloat(projectData.totaling.grantEstroSum).toFixed(2)} €</div>
    </>)
}