import {Text, View} from "@react-pdf/renderer";
import {styles, stylesTotal} from "./Styles/Styles";
import React from "react";
import {useTranslation} from "react-i18next";
import { Colors } from "../../../components/theme";
import { format_datetime_convert } from "../../tools";

const OtherDataPDF = ({note, showGrant, totaling, quote_acceptance}) => {
    const {t} = useTranslation()

    return(<>
        
        {showGrant || note ?
            <View style={{marginHorizontal: 30, breakInside: 'avoid'}}>
                <Text style={[styles.redTitle,{padding:'5px',paddingLeft: 0}]}>
                    {t("offer.pdf_document.texts.additional_infos_title")}
                </Text>

                {showGrant && (
                    <Text style={{marginTop:'5px', textAlign: "justify"}}>
                        {t(
                            'offer.pdf_document.texts.project_costs',
                            {estroSum:`${totaling.estroSum}`, grantEstro:`${totaling.grantEstroSum}`}
                        )}
                    </Text>
                )}

                {note && (
                    <Text style={{marginTop:'5px', textAlign: "justify"}}>
                        {note}
                    </Text>
                )}

                
            </View>
            : null
        }

        {quote_acceptance && typeof quote_acceptance === 'object' && Object.keys(quote_acceptance).length > 0 &&
            <View style={{marginHorizontal: 30}}>
                {quote_acceptance.status && (
                    <Text style={{ marginTop: '10px' }}>
                        {t("offer.pdf_document.status.title")}
                    </Text>
                )}
                {quote_acceptance.status === 'new' && (
                    <Text style={{ color: Colors.darkOrange }}>
                        {t("offer.pdf_document.status.waiting_pdf_message")}
                    </Text>
                )}
                {quote_acceptance.status === 'waiting' && (
                    <Text style={{ color: Colors.darkOrange }}>
                        {t("offer.pdf_document.status.waiting_pdf_message")}
                    </Text>
                )}
                {quote_acceptance.status === 'accepted' && (
                    <Text style={{ color: Colors.darkGreen }}>
                        {t("offer.pdf_document.status.accepted_message", {
                            person: `${quote_acceptance.person}`,
                        })}
                        {"\n"}
                        {t("offer.pdf_document.status.signature_date", {
                            dateTime: format_datetime_convert(new Date(quote_acceptance.date_time)),
                        })}
                    </Text>
                )}
                {quote_acceptance.status === 'rejected' && (
                    <Text style={{ color: Colors.darkRed }}>
                        {t("offer.pdf_document.status.rejected_message", {
                            person: `${quote_acceptance.person}`,
                        })}
                        {"\n"}
                        {t("offer.pdf_document.status.signature_date", {
                            dateTime: format_datetime_convert(new Date(quote_acceptance.date_time)),
                        })}
                        {"\n"}
                        {t("offer.pdf_document.status.reason_title")}
                        {"\n"}
                        {quote_acceptance.reason}
                    </Text>
                )}
            </View>
        }
    </>)
}

export default OtherDataPDF

