import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {usePDF} from "@react-pdf/renderer";
import {PDFFile} from "../../../../../../helpers/PDFSupport/offers/PDFFile";
import { saveAs } from 'file-saver';
import PopupForm from "../../../../../../components/ui/PopupForm";
import {useNavigate} from "react-router-dom";
// import PropTypes from "prop-types";

// function pdfData(data) {
//     return {
//         estimateID: data.estimateID,
//         calculation: data.calculation,
//         showEstroGrant: data.showEstroGrant,
//         client: data.client,
//         estimateDate: data.estimateDate,
//         firm: data.firm,
//         project: data.project,
//         program: data.program,
//         comment: data.comment,
//     }
// }

const popupLoadingPDF= (t) => ({
    show: true,
    variant: 'loading',
    title: t("popups.generate_pdf.loading"),
    message: t("popups.generate_pdf.progress")
})

const popupGeneratePDF= (t) => ({
    show: true,
    variant: 'success',
    leftBtn: t('common.button.download'),
    rightBtn: t('common.button.cancel'),
    title: t('popups.generate_pdf.title'),
    message: t("popups.generate_pdf.success")
})


const GeneratorPDF = ({
                          dataToRender,
                          offer_processName,
                          dispatch,
                          popupError,
                          popupInitial,
                          saveEstimate,
                          calculationID
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [popupPDF, setPopupPDF] = useState(popupInitial)
    const [isGeneratePDF, setIsGeneratePDF] = useState(false)
    const [estimateID, setEstimateID] = useState(0)
    // const document = <PDFFile doc={dataToRender} estimateID={estimateID}/>
    const [instance, updateInstance] = usePDF()

    useEffect(() => {
        if (instance.loading) {
            setPopupPDF(popupLoadingPDF(t))
        } else if (instance.error) {
            setPopupPDF(popupError)
        } else {
            if (isGeneratePDF) {
                const save_as = async () => {
                    await saveAs(instance.url, `${offer_processName}_${estimateID}.pdf`);
                }
                save_as()
                // await dispatch({type: "SET_GENERATE_PDF", isGeneratePDF: true, estimateID: okSave.pk})
                // window.location.assign(instance.url)
                navigate("/projects/in-preparation/"+dataToRender.project.id);
            } else setPopupPDF(popupGeneratePDF(t))
        }
    }, [instance, isGeneratePDF]);

    const handlePopup = async popupDict => {
        if (popupDict.type === 'save') {
            const okSave = await saveEstimate(dataToRender, calculationID)
            if (okSave) {
                await setEstimateID(okSave.pk)
                await updateInstance(<PDFFile doc={dataToRender} estimateID={okSave.pk}/>)
                await setIsGeneratePDF(true)
            } else {
                dispatch({type: "SET_POPUP", popup: popupError})
            }
        }

        if (popupDict.type === 'ok') {
            dispatch({type: "SET_POPUP", popup: popupError})
        }
        if (popupDict.type === 'cancel') {
            setPopupPDF(popupInitial)
            dispatch({type: "SET_GENERATE_PDF", isGeneratePDF: false, estimateID: undefined })
        }
    };

    return popupPDF.show ? <PopupForm
        handlePopup={handlePopup}
        variant={popupPDF.variant}
        title={t(popupPDF.title)}
        message={t(popupPDF.message)}
        leftBtn={popupPDF.leftBtn}
        rightBtn={popupPDF.rightBtn}
        okBtn={popupPDF.okBtn}
        context={popupPDF.context}
    /> : null;

}


export {
    GeneratorPDF,
}
