import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {INITIAL_renderDataToPDF, popupInitial} from "../../../../../../helpers/PDFSupport/offers/tools";
import get_quote_api from "../../../../../../helpers/PDFSupport/offers/get_quote_api";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";
import {clientQuoteDetailPDFAPI} from "../../../../../../services/api/project";
import {RenderDataToPDF} from "../../../../../../helpers/PDFSupport/RenderDataToPDF";
import {popupError} from "../../../../../../components/ui/Popup/feedBack";
import {clientDetailAPI, firmsListAPI} from "../../../../../../services/api/clients";
import Logo from "../../../../../../components/ui/Logo";
import LanguageButtons from "../../../../../../components/ui/LanguageButtons";
import { Colors } from "../../../../../../components/theme";

const LogoStyle = {
    maxWidth: '140px',
    width: '100%',
}


const QuoteClientConfirm = () => {
    const {t} = useTranslation();
    const [pdfData, setPdfData] = useState(INITIAL_renderDataToPDF);
    const [isGeneratePDF, setIsGeneratePDF] = useState(false)
    const params = useParams();

    useEffect(() => {
        const getDataAPI = async () => {
            const [quoteDataCalculation, quoteData] = await Promise.all([get_quote_api(params.quote_link), clientQuoteDetailPDFAPI(params.quote_link)])

            // console.info("Response quoteData:", quoteData);
            // console.info("Response quoteDataCalculation:", quoteDataCalculation);

            if (quoteData.status === 200) {
                const dataQuote = {
                    calculation: quoteDataCalculation,
                    client: {client_data: quoteData.data.clientData},
                    firm: quoteData.data.firmData,
                    estimate: quoteData.data.id,
                    estimateDate: quoteData.data.estimate_date, //.toLocaleDateString("de-DE"),
                    comment: quoteDataCalculation.comment,
                    typeData: "quote",
                    showEstroGrant: quoteDataCalculation.showEstroGrant,
                    language: quoteData.data.language,
                    documentDate: quoteData.data.pdf_date,
                    documentID: quoteData.data.id,
                    program: quoteData.data.program,
                    status: quoteData.data.status,
                    acceptance_cn: quoteData.data.acceptance_cn,
                    acceptance_firm: quoteData.data.acceptance_firm,
                    acceptance_reason: quoteData.data.acceptance_reason,
                    acceptance_datetime: quoteData.data.acceptance_datetime,
                    estimateFormDataVisible: false,
                    project: quoteData.data.project,
                    // estimateID: undefined,
                }

                // dataQuote.propTypes = {
                //     calculation: PropTypes.object,
                // }
                setPdfData(dataQuote)
            }
        }
        getDataAPI()
    }, [])

    const handlePDFClick =() => {
        console.log("Download pdf")
        setIsGeneratePDF(true)
    }

    return(
    <>
        <div className="py-4" style={{backgroundColor:'#f7f4f1', marginTop: '-44px', marginBottom:'-85px', minHeight: '100vh'}}>
            <div className="container">
                <div className='row align-items-center mb-3'>
                    <div className='col px-0'>
                        <a href="https://estro.lu">
                            <Logo styling={LogoStyle}/>
                        </a>
                    </div>
                    <div className='col px-0'>
                        <div style={{maxWidth:'120px', marginLeft:'5px', marginRight:'5px0', float:'right'}}>
                            <LanguageButtons />
                        </div>
                    </div>
                </div>
                
                <div className='row mb-3' style={{textAlign:'center', paddingTop:'80px'}}>
                    <p className="pb-4" style={{fontSize:'1.3em'}}>
                        {t("offer.after_sending_form_page")}
                    </p>
                    <a onClick={handlePDFClick}>
                        <FontAwesomeIcon icon={faFilePdf} className="me-2"/>
                        {t("common.button.download_pdf")}
                    </a>
                    <p style={{marginTop:'150px', color:`${Colors.mediumGray}`}}>
                        {t("offer.close_window")}
                    </p>
                </div>
            </div>
        </div>
        
        {isGeneratePDF
            ? <RenderDataToPDF
                dataToRender={pdfData}
                processName="quote"
                dispatch={setIsGeneratePDF}
                popupError={popupError}
                popupInitial={popupInitial}
            />
            : null
        }
    </>)
}


export default QuoteClientConfirm
