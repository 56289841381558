import {Text, View} from "@react-pdf/renderer";
import {styles, stylesTotal} from "./Styles/Styles";
import {useTranslation} from "react-i18next";
import {calculation_sections_sum} from "../../tools";

const Total = ({projProgram, projectData, musiciansSum}) => {
    const { t } = useTranslation();
    const renting = calculation_sections_sum(projectData.renting).client_cost
    const transport = calculation_sections_sum(projectData.transport).client_cost
    const archiving = calculation_sections_sum(projectData.archiving).client_cost
    const administration = calculation_sections_sum(projectData.administration).client_cost + archiving
    let musicians_summary = musiciansSum

    const total = musicians_summary + renting + transport + administration  //administration includes archiving

    return (
        <View style={{paddingVertical:15, marginHorizontal:30, flexDirection: 'row', wrap: 'nowrap'}}>
            <View style={{width: '50%'}}>
                <Text style={[styles.redTitle,{marginBottom:'9px'}]}>{t("offer.pdf_document.texts.programme_title")}</Text>
                <Text>{projProgram ? projProgram : t("offer.pdf_document.texts.to_define")}</Text>
            </View>

            <View style={{width: '50%'}}>
                <View>
                    <View style={stylesTotal.entryLine}>
                        <Text style={[stylesTotal.service, styles.redTitle, {marginBottom:'5px'}]}>
                            {t("offer.pdf_document.texts.service_title")}
                        </Text>
                        <Text style={[styles.redTitle,{width:'100%', textAlign:'right', marginBottom:'5px'}]}>
                            {t("offer.pdf_document.texts.costs")}
                        </Text>
                    </View>
                    <View style={stylesTotal.entryLine}>
                        <Text style={stylesTotal.service}>
                            {t("offer.pdf_document.texts.musicians")}
                        </Text>
                        <Text style={stylesTotal.costs}>
                            {parseFloat(musicians_summary).toFixed(2)} €
                        </Text>
                    </View>
                    {renting > 0
                        ? <View style={[stylesTotal.entryLine,{borderTop:"1px dashed #000"}]}>
                            <Text style={stylesTotal.service}>
                                {t("offer.pdf_document.texts.renting")}
                            </Text>
                            <Text style={stylesTotal.costs}>
                                {renting.toFixed(2)} €
                            </Text>
                        </View>
                        : null
                    }
                    {transport > 0
                    ? <View style={[stylesTotal.entryLine,{borderTop:"1px dashed #000"}]}>
                            <Text style={stylesTotal.service}>
                                {t("offer.pdf_document.texts.transport")}
                            </Text>
                            <Text style={stylesTotal.costs}>
                                {transport.toFixed(2)} €
                            </Text>
                    </View>
                    : null}

                    <View style={[stylesTotal.entryLine,{borderTop:"1px dashed #000"}]}>
                        <Text style={stylesTotal.service}>
                            {t("offer.pdf_document.texts.administration")}
                        </Text>
                        <Text style={stylesTotal.costs}>
                            {administration.toFixed(2)} €
                        </Text>
                    </View>

                    <View style={[stylesTotal.entryLine,{borderTop:"1px solid #000"}]}>
                        <Text style={[stylesTotal.service,{fontFamily:'Helvetica-Bold'}]}>
                            {t("offer.pdf_document.texts.total")}
                        </Text>
                        <Text style={[stylesTotal.costs,{fontFamily:'Helvetica-Bold'}]}>
                            {total.toFixed(2)} €
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    );
}

export default Total
